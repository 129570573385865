.breadcrumb {
	> li {
		display: inline-block;
		font-weight: 500;
		font-size: 14px;
		line-height: 18px;
		a {
			color: $bk;
			font-weight: 500;
			font-size: 14px;
			line-height: 18px;
		}
		&:first-child {
			a span {
				font-size: 14px;
				line-height: 18px;
			}
		}
		+ li:before {
			content: "-"; // Unicode space added since inline-block means non-collapsing white-space
			padding: 0 5px;
			color: $bk;
		}
	}
	> .active {
		
	}
}