@media (min-width: 1921px) {
	body {
		max-width: 1920px;
		margin: 0 auto;
	}
}
@media (min-width: 1025px) {
	#block4 img {
		position: absolute;
		right: -100px;
	}
	#block5 img {
		position: absolute;
		left: -100px;
	}
	#block6 img {
		position: absolute;
		right: -120px;
	}
	#block7 img {
		position: absolute;
		left: -90px;
	}
	#block8 img {
		position: absolute;
		right: -80px;
	}
}

@media (min-width: 992px) {
	i.click-allcat {
		display: none;
	}
	#cart {
		margin-left: 0;
	}

}

@media (max-width: $screen-md-max) {
	.mfilter-content {
		#mfilter-opts-price-min, #mfilter-opts-price-max {
			width: 75px;
			min-width: 75px;
			padding: 9px 7px;
		}
	}
	.footer-center .main-footer {
		margin-left: 0;
	}
	.pav-megamenu .navbar-nav > li > a {
		padding: 14px 10px;
		font-size: 14px;
	}
	.producttabs {
		.btn-dark {
			@include size(30px, 30px);
			font-size: $font-size-base - 3;
			line-height: 30px;
		}
	}
	.feature-box-v1 {
		padding: 12px 20px;
		.fbox-body.white strong {
			font-size: 18px;
		}
	}
	.home3 {
		.featured-category {
			ul li {
				.caption {
					padding: 20px 5px;
				}
			}
			.image {
				margin: 5px 0 5px 10px;
			}
		}
	}
	.product-info {
		.product-meta {
			.btn-sm, .btn-group-sm > .btn {
				padding: 0 5px;
			}
		}
	}
	.mfilter-free-container {
		top: 174px;
	}
	.header-bottom {
		.nav-search {
			margin-bottom: 7px;
		}
	}
	.refine-search ul li a span, .refine-search ol li a span {
		line-height: 15px;
		font-size: 12px;
		overflow: hidden;
	}
}

@media (max-width: 1024px) and (min-width: 769px) {
	#block5 {
		background-position: 55% 0;
		.title {
			margin-top: 65px;
		}
	}

}

@media (max-width: $screen-md-max) and (min-width: $screen-md) {
	.logo-store {
		img {
			margin-top: 4px;
		}
	}
	.home3 {
		.widget-blogs {
			.carousel-controls-v1 {
				top: 45px;
				.carousel-control.left {
					left: -65px;
				}
				.carousel-control {
					border: none;
				}
			}
		}
	}
}

@media (max-width: $screen-sm-max) {
	.widget-images {
		margin-bottom: 30px;
	}
	.home3 .layerslider-wrapper {
		margin-bottom: 30px;
	}
	.panel-v1 > .panel-heading .panel-title {
		font-size: 18px;
	}
	.productdeals-v1 .product-block .product-meta {
		text-align: center;
	}
	.tab-v1 .nav-tabs > li > a {
		padding: 0 10px;
	}
	.footer-center {
		.panel {
			margin-bottom: 15px;
		}
	}
	#searchtop {
		margin-top: 5px;
	}
	#pavo-footer-center {
		.container {
			padding-right: 25px;
			.footer-left {
				float: none;
				margin: 0 auto 30px auto;
			}
		}
	}
	.featured-category {
		ul li {
			.caption {
				padding: 20px 5px;
			}
		}
		.image {
			margin: 5px 0 5px 10px;
		}
	}
	.zoomContainer {
		display: none;
	}
	.product-grid {
		.product-block {
			.product-grid .product-block {
				width: calc(100% / 2 - 33px);
				margin: 5px;
			}
			width: calc(100% / 2 - 33px);
		}
	}
	.header-bottom {
		& > .container {
			height: 100px;
		}
		.logo-container {
			text-align: center;
			flex: 0 0 200px;
			order: 1;
			img {
				max-width: 100%;
			}
		}
		#topcontacts {
			order: 2;
		}
		.headercart {
			order: 3;
			flex: 0 1 150px;
			min-width: 150px;
		}
	}
	.header-menu > .container {
		padding-left: 0;
	}
	#powered {
		.copyright {
			order: 2;
		}
		.payment {
			order: 1;
			text-align: center;
			.payment-img {
				float: none !important;
				max-width: 80%;
				display: inline-block;
			}
		}
	}
	body .mfilter-free-container {
		top: 244px !important;
		padding: 0 !important;
		margin-right: -222px;
		margin-left: auto;
		right: 0;
		left: auto;
		border-color: $bc;
		&.mfilter-direction-rtl {
			margin-right: -222px;
		}
		#mfilter-free-container-0 {
			padding: 5px 10px !important;
			overflow: auto !important;
		}
		.mfilter-box {
			margin-bottom: 5px;
		}
		.mfilter-free-button {
			background: $theme-color;
			margin: 0 220px 0 -45px;
			border: 1px solid $bc;
			padding: 9px 5px;
			top: -1px;
			width: 33px;
			height: 23px;
			&:before {
				content: '\f1f3';
				font-family: $fa;
				color: $white;
				font-size: 28px;
				line-height: 23px;
				padding: 3px;
				left: -1px;
				position: relative;
			}
		}
		#mfilter-opts-price-min, #mfilter-opts-price-max {
			width: 65px;
			min-width: 65px;
			padding: 9px 7px;
		}
	}
	.pagination > li > a, .pagination > li > span {
		margin-bottom: 8px;
	}
	.tree-menu ul li:hover {
		background-color: $white;
		a {
			color: $bk;
		}
	}
	.breadcrumbs {
		padding: 15px 0;
	}
	.h1_container {
		&:before {
			margin: 33px 10px 10px 0;
		}
		h1 {
			padding: 18px 15px 21px 0;
			font-size: 26px;
		}
	}
	.product-compare-header {
		width: 60px;
		position: relative;
		flex: 0;
		padding: 13px 10px 0 14px;
		a {
			color: $bk;
			&:hover {
				text-decoration: none;
			}
		}
		.badge {
			right: 7px;
		}
	}
	#cart {
		float: right;
		.icon-cart {
			padding: 0 17px;
			height: 50px;
			i {
				line-height: initial;
				vertical-align: -25px;
			}
		}
	}
	.cat_all {
		& > li {
			position: relative;
			.click-allcat {
				cursor: pointer;
				display: inline-block;
				padding: 1px 15px;
				top: 8px;
				position: absolute;
				right: 0;
				z-index: 1;
				color: $bk;
			}
			&.haschildren {

				ul {
					display: none;
				}
				& > a {
					width: calc(100% - 40px);
					display: inline-block;
				}
				&.opened ul {
					display: block;
				}
			}
		}
	}
	.category_widget {
		.type1 {
			height: 300px;
			h2 {
				a {
					font-size: 15px;
					padding: 27px 0;
				}
			}
		}
	}
	.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
		width: calc(25% - 20px);
	}
	.main-menu {
		.canvas-menu {
			color: $wh;
			padding: 0 15px;
		}
	}
	.tree-menu ul ul li {
		padding: 2px 0;
	}
	.product-info .tab-content .product-grid.no-scroll .product-block {
		margin: 4px 2px;
		width: calc(100% / 3 - 7px);
	}
	.container {
		width: 100%;
		padding: 0 25px;
	}
	body {
		background: url(#{$image-theme-path}bg1_768.png) top left no-repeat $wh;
	}
	body.common-home, body.page-category, body.page-checkout-buy {
		background: url(#{$image-theme-path}bg1_768.png) top left no-repeat #F8EFE1;
	}
	.canvas-menu {
		color: $wh;
		margin: 45px 0 0 25px;
		font-size: 40px;
	}
	#search_blk {
		position: absolute;
		right: 45px;
		bottom: 20px;
		.nav-search {
			border-bottom: 1px solid #413833;
		}
	}
	header .phone-work {
		margin-bottom: 15px;
	}
	.search_block #searchtop input {
		position: relative;
		background: none;
		border: 0;
		opacity: 1;
		right: auto;
		width: 180px;
	}
	.search_block #searchtop.is-active input {
		width: 180px;
	}
	#block1, #block4, #block5, #block6, #block7, #block8, #subscribe_block {
		.title {
			font-size: 40px;
			line-height: 44px;
			margin: 5px 0 20px 0;
		}
		.text p {
			font-size: 16px;
			line-height: 24px;
		}
	}
	#block2 {
		background-position: -350px -10px;
	}
	#block4 {
		padding: 125px 0;
		min-height: auto;
		background: url(#{$image-theme-path}bg4_768.png) left center no-repeat;
	}
	#block5 {
		margin: 0 0 25px 0;
		padding-top: 100px;
		background-position: top -250px;
	}
	#block6 {
		margin: 0;
		padding: 120px 0;
		min-height: auto;
		background: url(#{$image-theme-path}bg6_768.png) left center no-repeat;
	}
	#block7 {
		margin: 0;
		padding: 50px 0;
		min-height: auto;
		background: url(#{$image-theme-path}bg7_768.png) right center no-repeat;
	}
	#block8 {
		margin: 0;
		padding: 150px 0 20px 0;
		min-height: auto;
		background: url(#{$image-theme-path}bg81_768.png) left top no-repeat;
	}
	#block9 {
		margin: 20px 0;
		background-size: cover;
	}
	#subscribe_block .title {
		margin: 30px 0 40px 0
	}
	#subscribe_block #subscribe_type .form-group {
		display: block;
	}
	#subscribe_block #subscribe_type input {
		width: 100%;
		box-sizing: border-box;
	}
	#subscribe_block #subscribe_type button {
		left: auto;
		right: 0;
	}
	#subscribe_block .input-control {
		font-size: 14px;
		line-height: 18px;
	}
	#footer {
		background: url(#{$image-theme-path}logo_footer-01.png) left top no-repeat #949494;
		background-size: cover;
		font-size: 14px;
		line-height: 18px;
	}
	.footer-center {
		.footer-name {
			span,
			img {
				margin-top: 0;
			}
		}
	}
	#footer {
		ul {
			li {
				&.ft-wt {
					& > span {
						display: block;
					}
				}
				&.ft-phone {
					margin-top: 10px;
					a {
						margin-bottom: 0;
					}
				}
				&.ft-mail {
					a {
						padding: 10px 0 0 0;
					}
				}
			}
		}
		hr {
			margin: 25px 0;
		}
	}
	.all_category {
		.item {
			.link {
				a {
					margin: 20px 0 8px 0;
				}
			}
		}
	}
	.cart_all {
		margin: 0 0 25px 0;
		background: $wh;
	}
	#checkout-form {
		.cart_block {
			margin-top: 25px;
			margin-bottom: 36px;
		}
		.form-group {
			margin-bottom: 0;
		}
		#input-comment {
			margin-top: 25px;
		}
		.buttons {
			padding: 12px 0;
		}
	}
	#offcanvasmenu .navbar-nav > li > a {
		box-shadow: none;
		text-transform: none;
	}
	#offcanvasmenu .dropdown-menu a {
		font-family: $hf;
		font-style: normal;
		font-weight: normal;
		font-size: 24px;
		line-height: 32px;
		padding-left: 25px;

	}
	#offcanvasmenu .navbar-nav .dropdown-menu ul li {
		a {
			padding-left: 75px;
			border-bottom: 1px solid #F8EFE1;
			min-height: 64px;
			line-height: 64px;
			display: block;
			margin: 5px 0;
		}
		&:nth-child(1) a {
			background: url(#{$image-theme-path}menu/sling.png) 0 center no-repeat;
		}
		&:nth-child(2) a {
			background: url(#{$image-theme-path}menu/first.png) 0 center no-repeat;
		}
		&:nth-child(3) a {
			background: url(#{$image-theme-path}menu/purecotton.png) 0 center no-repeat;
		}
		&:nth-child(4) a {
			background: url(#{$image-theme-path}menu/blackline.png) 0 center no-repeat;
		}
		&:nth-child(5) a {
			background: url(#{$image-theme-path}menu/xt.png) 0 center no-repeat;
		}
		&:nth-child(6) a {
			background: url(#{$image-theme-path}menu/duo.png) 0 center no-repeat;
		}
		&:nth-child(7) a {
			background: url(#{$image-theme-path}menu/limitededition.png) 0 center no-repeat;
		}
		&:nth-child(8) a {
			background: url(#{$image-theme-path}menu/amazonas.png) 0 center no-repeat;
		}
		&:nth-child(9) a {
			background: url(#{$image-theme-path}menu/bellybutton.png) 0 center no-repeat;
		}
		&:nth-child(10) a {
			background: url(#{$image-theme-path}menu/accessories.png) 0 center no-repeat;
		}
	}
	#subscribe_block #subscribe_type button {
		width: 56px;
	}
}

@media (max-width: $screen-sm) {

	.alert {
		margin: 10px 15px;
	}
	.quickview, .zoom {
		display: none !important;
	}

}

@media (max-width: $screen-xs-max) {
	body {
		background: $wh;
	}
	body.common-home, body.page-category, body.page-checkout-buy {
		background: #F8EFE1;
	}
	.row-offcanvas-left {
		&.active {
			left: 100%;
		}
		.sidebar-offcanvas {
			left: -100%;
		}
	}
	.sidebar-offcanvas {
		width: 100%;
	}
	h1, .h1, .product-info .title-product {
		font-size: 16px;
		line-height: 24px;
	}
	.product-info {
		margin-top: 0;
	}
	header {
		padding: 10px 0 9px 0;
		.text-right {
			text-align: center;
		}
	}
	.canvas-menu {
		margin: 10px 0 0 0;
		color: $bk;
		line-height: 40px;
	}
	#cart {
		margin-top: 10px;
	}
	#search_blk {
		bottom: auto;
		top: 65px;
		z-index: 1;
	}
	#search_blk .nav-search {
		border: 0;
	}
	.search_block #searchtop input {
		position: absolute;
		background: $wh;
		border: 0;
		opacity: 0;
		right: -999px;
		width: 180px;
	}
	.search_block #searchtop.is-active input {
		width: 180px;
		position: absolute;
		top: 0;
		right: 40px;
	}
	.product-grid {
		.product-block {
			width: calc(100% / 2 - 12px);
		}
	}
	#block9 {
		.title {
			font-size: 24px;
		}
		.text {
			font-size: 16px;
		}
	}
	.storage_widget {
		padding: 15px;
		background: none;
		table tr td {
			display: block;
			width: 100%;
			&:last-child span {
				display: block;
				margin: 5px 0 0 0;
				text-align: center;
			}
			&#storage_address {
				padding-left: 50px !important;
				background: url(#{$image-theme-path}ic_shop_768.png) left center no-repeat;
				min-height: 32px;
			}
		}

	}
	.page-product .breadcrumb li {
		&:last-child {
			display: none;
		}
	}
	#product > .row, .otziv_full .orders {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		.order-1 {
			order: 1;
			width: 100%;
		}
		.order-2 {
			order: 2;
			width: 100%;
			margin: 25px 0;
		}
	}
	#checkout-form .cart_block {
		margin-top: 0
	}
	#checkout-form .buttons {
		padding: 0
	}
	#buy_form_container .vertical {
		display: block;
		margin: 0 0 35px 0;
		.padding-0 {
			padding: 0;
		}
		.prod_name {
			margin-bottom: 15px;
			display: inline-block;
		}
		.prod_price {
			margin-top: 15px;
		}
	}
	#coupon_form {
		padding-bottom: 25px;
	}
	.cart_all {
		margin-bottom: 0
	}
	.product-info .product_top {
		margin-bottom: 15px;
		border: 0;
	}
	.logo-container {
		width: 140px;
	}
	.topcontacts {
		width: calc(100% - 140px);
	}
	.featured-category ul li {
		width: 100%;
	}
	#powered ul.links {
		margin-bottom: 40px;
	}
	.producttabs {
		margin-top: 15px;
	}
	.widget-products .owl-stage {
		padding: 15px 0;
	}
	.tab-v3 {
		.nav-tabs {
			flex-wrap: wrap;
			border: none;
			& > li {
				width: 100%;
				text-align: center;
				border: none;
				&:last-child {
					border: none;
				}
				&.active a {
					border: none;
				}
				& > a {
					border: none;
					padding: 10px 15px;
					font-size: 18px;
				}
			}
		}
	}
	.h1_container {
		&:before {
			content: none;
		}
		h1 {
			width: 100%;
			font-size: 20px;
		}
	}
	.table-responsive {
		.input-group {
			.form-control {
				margin-right: 30px;
				padding: 5px;
			}
		}
	}
	.cart.pull-left {
		float: none !important;
		margin-bottom: 5px;
	}

	.checkout-cart table, .shopping-cart-table {
		background: $wh;
	}
	.shopping-cart-table {
		& > thead {
			display: none;
		}
		.shopping-cart-table__product-image img {
			max-width: 160px;
		}
		td.image-col {
			width: 100%;
			text-align: center !important;
		}
		&, & tbody, & td, & tr {
			display: block;
			border: 0;
			width: 100%;
		}
		td {
			position: relative;
			padding-left: 43% !important;
			text-align: left !important;
			white-space: normal !important;
			&:first-child {
				border-top: 0;
				padding: 0 !important;
				text-align: center !important;
			}
			div.th-title {
				display: block;
				position: absolute;
				left: 10px;
				width: 38%;
				padding-right: 10px;
				text-align: right;
				top: 50%;
				@include transform(translateY(-50%));
				font-weight: bold;
				text-transform: uppercase;
			}
			&.no-title {
				padding-left: 20px !important;
				text-align: center !important;
			}
		}
		.input-group-qty {
			display: inline-block;
			padding-right: 10px;
		}
	}

	.total-table {
		.text-right {
			padding-left: 60% !important;
		}
		td div.th-title {
			width: 53% !important;
		}
	}
	.header-bottom {
		#topcontacts {
			justify-content: center;
			min-width: 133px;
			.worktime {
				display: none;
			}
		}
	}
	.shopping-cart-btns {
		text-align: center;
		.btn.pull-left, .btn.pull-right {
			float: none !important;
			margin: 0 !important;
		}
	}
	.header-bottom {
		.main-menu {
			flex: 0 0 auto;
		}
		.nav-search {

		}
	}
	#powered .inner {
		flex-wrap: wrap;
		.copyright {
			order: 2;
			margin-bottom: 15px;
		}
		.payment {
			order: 1;
			.payment-img {
				max-width: 100%;
			}
		}
	}
	#input-enquiry {
		height: 100px;
	}
	.footer-center {
		.footer-left {
			ul {
				margin: 0 auto;
				li {
					max-width: 220px;
					margin: 0 auto;
				}
			}
		}
		.main-footer {
			flex-wrap: wrap;
			.box {
				width: 100%;
			}
		}
	}
	.breadcrumb > li {
		display: inline;
		font-size: 13px;
	}
	#slider_top, #block1, #block2, #block3, #block4, #block5, #block6, #block7, #block8, #subscribe_block {
		.title, .panel-title {
			font-size: 24px;
			line-height: 32px;
			margin: 5px 0 20px 0;
		}
		.text p {
			font-size: 16px;
			line-height: 24px;
		}
	}
	.desc-header {
		font-size: 24px !important;
		line-height: 32px !important;
		margin: 5px 0 20px 0 !important;
	}
	#block1 {
		margin-top: 40px;
	}
	#block3 {
		min-height: auto;
		background-size: cover;
		padding-bottom: 70px;
	}
	#block3 .block {
		width: 100%;
	}
	#block4, #block6, #block7, #block8, #block9 {
		padding: 50px 0;
		background: none;
		margin: 0;
	}
	.link {
		margin-bottom: 15px;

		a {
			font-size: 16px;
			line-height: 24px;

			&:after {
				content: '';
				width: 22px;
				height: 24px;
				background: url(#{$image-theme-path}arrow.png) right top no-repeat;
				position: absolute;
				right: 0;
				top: 50%;
				margin-top: -12px;
			}
		}
	}
	#block5 {
		background-position: -1050px 0;
		margin: 0;
		min-height: auto;
		padding-bottom: 50px;
	}
	.product-info .tab-content #spoiler_text {
		max-height: 570px;
	}
	.product-info .tab-content .pos_title {
		margin-top: 50px;
	}
	.refine-search {
		ul, ol {
			li {
				a {
					img {
						max-width: 100% !important;
					}
				}
			}
		}
	}
}

@media (max-width: 640px) {
	.success {
		width: 300px;
		margin-left: -150px;
		padding: 45px 15px 15px 15px;
		.success_button {
			padding: 6px;
			font-size: 13px;
		}
		& > div:first-child {
			margin-top: 20px;
		}
		br {
			display: none;
		}
	}
	.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
		width: calc(33.3% - 20px);
	}
	.product-info .tab-content .product-grid.no-scroll .product-block {
		margin: 4px 2px;
		width: calc(100% / 2 - 7px);
	}
	#subscribe_block #subscribe_type button {
		right: 0
	}
	.product-grid {
		.product-block {
			width: calc(100% / 2 - 35px);
		}
	}
}

@media (max-width: 570px) {
	.fancy_holiday_works {
		width: 280px !important;
		.fancybox-skin {
			padding: 30px 10px !important;
			background-color: $wh;
			.fancybox-inner {
				width: 260px !important;
			}
			.holiday_table_wrap {
				font-size: 14px;
			}
			.holiday_table {
				td {
					font-size: 14px;
				}
			}
		}
	}
	.holiday_works {
		top: 175px;
		width: 100vw;
		right: -11px;
	}
	#powered > .container, .h1_container h1 {
		padding: 0;
	}
	.breadcrumbs {
		padding: 15px 25px 0 25px;
		.container {
			padding: 0;
		}
	}
	.breadcrumbs .breadcrumb, .breadcrumb {
		padding: 0 0 20px 0;
	}
	h1, .h1 {
		padding-top: 0
	}
	body[class*="page-"] #content {
		padding-top: 0
	}
	.selectric-wrapper {
		width: 100%;
		text-align: left;
	}
	.selectric .label {
		text-align: left;
	}
	#products .products-block {
		margin: 0;
	}
	.header-bottom {
		& > .container {
			height: auto;
			position: relative;
		}
		.logo-container {
			flex: 0 0 150px;
			margin: 10px 0;
		}
		#topcontacts {
			position: absolute;
			right: 160px;
			flex: 0 1 auto;
			min-width: unset;
		}
		#topcontacts, .headercart {
			flex: 0 1 auto;
		}
		.headercart {
			min-width: 133px;
		}
		.topcontacts {
			justify-content: center;
			max-width: 35px;
			min-width: auto;
			.media-title {
				padding: 0;
			}
			.media-body {
				display: none;
			}
			em {
				font-size: 30px;
			}
		}
	}
	#searchtop {
		border: 0;
		.input-group-btn {
			height: 30px;
			&:hover {
				background: $tc;
			}
		}
		.btn-search {
			line-height: 14px;
		}
	}
	.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
		width: calc(50% - 20px);
	}
	.product-info .tab-content {
		.desc-header-wrapper .desc-header {
			display: block;
		}
		.scroll-button {
			margin-bottom: 15px;
			margin-left: 15px;
		}
	}
	#buy_form_container {
		margin: 0 -25px;
		#kupon .kupon_btn {
			margin-right: 25px;
		}
	}
	#checkout-form {
		margin: 0 -40px;
	}
	#checkout-form #button-order {
		width: 100%;
		padding: 0;
		text-align: center;
	}
}

@media (max-width: $screen-xs) {
	.product-filter .filter-right > div {
		margin-bottom: 5px;
		&.limit {
			display: none;
		}
	}
	.product-list .product-col .block-img {
		float: none;
		width: 100%;
	}
	.product-list .product-meta {
		padding: 20px;
	}
	.tab-v6 .nav-tabs > li > a {
		padding: 15px 10px;
		font-size: $font-size-base - 1;
	}
	.tab-v7 .nav-tabs > li.active::before {
		content: none;
	}
	.pav-blogs .blog-body {
		overflow: visible;
	}
	.product-info .tab-content .product-grid.no-scroll .product-block {
		margin: 4px 2px;
		width: calc(100% - 5px);
	}
	.header-bottom {
		.logo-container {
			flex: 0 0 130px;
			padding-right: 10px;
		}
		.headercart {
			min-width: 120px;
		}
		#topcontacts {
			right: 133px;
		}
	}
	.product-compare-header {
		padding-left: 0;
		width: 40px;
		.badge {
			right: 2px;
		}
	}
	#cart {
		.wrap-cart .badge {
			right: 0;
		}
		.icon-cart {
			padding: 0 10px;
		}
	}
}


@media (max-width: 432px) {
	.holiday_works{
		top: calc(66px + 24vw);
	}
	.fancy_holiday_works {
		 .fancybox-skin {
			 .fancybox-inner {
			 }
		 }
	 }
}

@media (max-width: 360px) {
	.category_widget {
		.type1 {
			h2 {
				a {
					font-size: 13px;
					padding: 28px 0;
				}
			}
		}
	}
	.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
		width: calc(50% - 20px);
	}
	.price .price-new {
		font-size: 18px;
	}
	.product-block .economy {
		font-size: 13px;
	}
	#block3 {
		padding-top: 30px;
	}
	#offcanvasmenu .navbar-nav .dropdown-menu ul li {
		a {
			min-height: 64px;
			line-height: normal;
		}
	}
}

@media (max-width: $screen-phone) {
	.product-grid {
		.product-block {
			width: 100%;
			margin: 0 0 25px 0;
		}
	}
}
