$bk: #413833 !default;
$wh: #FCFDFF !default;
$tc: #EBA682 !default;
$tch: #D7926E !default;
$tc2: #ed1c24 !default;

$lg: #f2f1ef !default;
$mg: #949494 !default;
$dg: #5c5c5c !default;
$ddg: #353536 !default;
$lb: #5c97bf !default;
$bc: #c4c4c4 !default;
$lbc: #ededed !default;
$b: #EBA682 !default;

$f: 'Gilroy', sans-serif !default;
$hf: 'Oswald', sans-serif !default;
$fa: "FontAwesome" !default;


@import "fonts";
@import "bootstrap";
@import "vars/opencart";
@import "vars/elements.vars";
@import "mixins/app";
@import "app/base";
@import "app/elements";
@import "app/form";
@import "app/layout";
@import "app/block";
@import "app/product";
@import "app/modules";
@import "app/menu";
@import "opencart/opencart-modules";
@import "opencart/opencart-pages";
@import "opencart/offcanvas";
@import "opencart/style";
@import "opencart/theme";

@import "opencart/modules/megamenu.scss";
@import "opencart/modules/sphinx.scss";

@import "app/custom";
@import "opencart/responsive";