@font-face {
	font-family: 'Gilroy';
	src: url('/fonts/gilroy/Gilroy-SemiBold.eot');
	src: url('/fonts/gilroy/Gilroy-SemiBold.eot?#iefix') format('embedded-opentype'),
	url('/fonts/gilroy/Gilroy-SemiBold.woff') format('woff'),
	url('/fonts/gilroy/Gilroy-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Gilroy';
	src: url('/fonts/gilroy/Gilroy-Regular.eot');
	src: url('/fonts/gilroy/Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
	url('/fonts/gilroy/Gilroy-Regular.woff') format('woff'),
	url('/fonts/gilroy/Gilroy-Regular.ttf') format('truetype');
	font-weight: normal;
    font-style: normal;
}
@font-face {
	font-family: 'Gilroy';
	src: url('/fonts/gilroy/Gilroy-Medium.eot');
	src: url('/fonts/gilroy/Gilroy-Medium.eot?#iefix') format('embedded-opentype'),
	url('/fonts/gilroy/Gilroy-Medium.woff') format('woff'),
	url('/fonts/gilroy/Gilroy-Medium.ttf') format('truetype');
	font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Oswald';
    src: url('/fonts/oswald/subset-Oswald-Regular.eot');
    src: url('/fonts/oswald/subset-Oswald-Regular.eot?#iefix') format('embedded-opentype'),
        url('/fonts/oswald/subset-Oswald-Regular.woff2') format('woff2'),
        url('/fonts/oswald/subset-Oswald-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Oswald';
    src: url('/fonts/oswald/subset-Oswald-Medium.eot');
    src: url('/fonts/oswald/subset-Oswald-Medium.eot?#iefix') format('embedded-opentype'),
        url('/fonts/oswald/subset-Oswald-Medium.woff2') format('woff2'),
        url('/fonts/oswald/subset-Oswald-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}