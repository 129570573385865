
.owl-carousel-play .owl-carousel {
	margin: 0;
	border: none;
}

.products-block {
	.owl-carousel {
		margin: 0;
	}
}

.price {
	font-weight: 600;
	.price-new {
		color: $bk;
		font-size: 20px;
	}
	.price-old {
		text-decoration: line-through;
		color: $mg;
	}
	&.detail {
		vertical-align: top;
		.price-new {
			font-weight: 500;
			font-size: 30px;
			line-height: 40px;
			display: block;
			float: left;
		}
		.price-old {
			margin: 5px 0 0 10px;
			font-weight: 500;
			line-height: 18px;
			color: #949494;
			display: inline-block;
		}
		ul {
			margin: 0;
		}
	}
}

.cheap_invoice {
	a {
		font-weight: 500;
		font-size: 14px;
		line-height: 18px;
		margin-bottom: 5px;
		display: block;
		color: $bk;
		&:hover {
			color: $tc;
			text-decoration: none;
		}
	}
}

.product-block {
	position: relative;
	background-color: #FCFDFF;
	border-top: 1px solid transparent;
	box-shadow: 0px 2px 6px rgba(88, 66, 55, 0.1), 0px 0px 2px rgba(134, 96, 77, 0.2);
	@include rounded-corners(8px);
	.description {
		display: none;
	}
	.product-meta {
		line-height: 25px;
		padding: 0;
	}
	.bottom {
		display: block;
	}
	.price {

		span {
			display: block;
			height: 25px;
		}
	}
	.product_top {
		margin-bottom: 20px;
	}
	.name {
		margin: 10px 0 8px 0;
		font-size: $font-size-base;
		font-family: $font-family-base;
		text-transform: inherit;
		overflow: hidden;
		font-weight: 600;
		a {
			color: $black;
			line-height: 20px;
			padding: 5px 0;
			&:hover {
				color: $bk;
			}
			span {
				text-align: center;
				display: block;
				&.title1 {
					font-weight: 400;
				}
				&.title2 {
					font-family: $hf;
					font-weight: 500;
					font-size: 18px;
					line-height: 26px;
				}
			}
		}
		.rating {
			font-size: 14px;
			margin-top: 7px;
			text-align: center;
		}
	}
	.product-colors {
		font-weight: 500;
		color: #949494;
	}
	.economy {
		padding: 2px 9px;
		background: rgba(246, 212, 173, 0.3);
		font-weight: 500;
		font-size: 14px;
		line-height: 18px;
		margin: 27px 0 0 0;
		border-radius: 16px;
		text-align: center;
		color: #413833;
	}
	.image {
		position: relative;
		overflow: hidden;
		@include transition(all 0.4s ease 0s);
		padding: 10px 10px 0 10px;
		text-align: center;
		img {
			max-width: 171px;
			display: inline-block;
		}
	}
	.top {
		position: relative;
		padding: 0 20px 10px 20px;
		.eco {
			padding-right: 0;
		}
	}
}

.cart {
	position: relative;
	@include transition(all 0.4s ease 0s);
	padding-bottom: 0px;
	button {
		background: none;
		width: 100%;
		display: block;
		border: 0;
		border-top: 1px solid #F8EFE1;
		border-radius: 0 0 8px 8px;
		color: #EBA682;
		text-align: center;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		height: 43px;
		-webkit-transition-property: none;
		-moz-transition-property: none;
		-o-transition-property: none;
		transition-property: none;
		span {
			display: inline-block;
			padding-left: 30px;
			background: url(#{$image-theme-path}cart.png) left top no-repeat;
		}
		&:hover, &:focus {
			border-top: 1px solid #EBA682;
			background-color: #EBA682;
			span {
				background: url(#{$image-theme-path}cart.png) left bottom no-repeat;
			}
		}

	}
	.icon-cart {
		display: block;
		@include size(16px, 17px);
	}
}

.product-label {
	font-size: $font-size-base - 2;
	font-weight: bold;
	text-transform: uppercase;
	z-index: 99;
	position: absolute;
	text-align: center;
	border: 1px solid $tc;
	line-height: 17px;
	padding: 5px 15px;
	top: 7px;
	&.sale {
		background: url(#{$image-theme-path}skidka.png) top center no-repeat;
		border: 0;
		left: 16px;
		top: 16px;
		width: 32px;
		height: 32px;
	}
	&.new {
		right: 7px;
		background-color: $wh;
		color: $tc;
	}
	&.markdown-product {
		left: 16px;
		top: 16px;
		background-color: $wh;
		color: $tc;
	}
}

.cart.pull-left {
	float: none !important;
	margin-bottom: 5px;
}

.product-info .tab-content {
	border: none;
	padding: 0;
	margin-top: 30px;
	margin-bottom: 30px;
	.desc-header-wrapper {
		margin-top: 30px;
		margin-bottom: 15px;
		.desc-header {
			font-family: $hf;
			font-weight: 500;
			font-size: 40px;
			line-height: 45px;
			text-transform: uppercase;
			color: $bk !important;
		}
	}
	.main-header {
		font-family: $hf;
		font-weight: 500;
		font-size: 40px;
		line-height: 45px;
		text-transform: uppercase;
		margin: 15px 0 25px 0;
	}

	.pos_title {
		font-family: $hf;
		font-size: 24px;
		line-height: 32px;
		margin: 0 0 15px 0;
	}
	.pos_block {
		background: #C3D9F3;
		border-radius: 8px;
		padding: 32px 24px 20px 24px;
		margin: 0 0 25px 0;
		p {
			font-size: 16px;
			line-height: 24px;
			margin: 0 0 5px 0;
			text-align: left;
			img {
				max-width: 82px !important;
				margin-left: -22px;
			}
		}

	}
	#spoiler_text {
		max-height: 610px;
		overflow: hidden;
		position: relative;
		&:after {
			position: absolute;
			bottom: 0;
			width: 100%;
			height: 30px;
			content: '';
			background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(255,255,255,1) 100%);
		}
	}
	#spoiler_text.active {
		max-height: none;&:after {content: none;}
	}
	#spoiler_button {
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		border-radius: 3px;
		padding: 8px 15px;
		margin: 15px 0;
		border: 1px solid $bk;
		display: inline-block;
		text-transform: uppercase;
		&:hover {
			color: $wh;
			border: 1px solid $b;
			background: $b;
			cursor: pointer;
		}
	}
	#tab-downloads {
		ul {
			padding: 0;
			li {
				a {
					color: $bk;
					font-size: 16px;
					line-height: 24px;
					background: url(#{$image-theme-path}ic_download.svg) left 0px no-repeat;
					padding-left: 30px;
					display: block;
					margin: 0 0 5px 0;
				}
			}
		}
	}

	#tab-description {
		& > * {
			font-family: $f !important;
			color: $bk !important;
		}
		ul {
			margin-top: 0 !important;
		}
		p, li {
			font-family: $f !important;
			font-size: 16px !important;
			line-height: 24px !important;

		}
		h2 {
			font-family: $f !important;
			font-weight: 600 !important;
			font-size: 20px !important;
			line-height: 24px !important;
			color: $bk !important;
		}
		h3 {
			font-family: $f !important;
			font-weight: 600 !important;
			font-size: 18px !important;
			line-height: 22px !important;
			color: $bk !important;
		}
	}
	#tab-specification {
		td {
			padding: 10px 15px !important;
			color: $bk !important;
		}
	}
	.owl-related {
		.owl-next, .owl-prev {
			top: -57px;
		}
		.owl-next {
			right: 18px;
		}
		.owl-prev {
			right: 48px;
		}
		.owl-stage {
			padding: 0;
		}
		.product-block {
			width: 100%;
			margin: 0;
			.image {
				border: none;
			}
		}
	}
	.widget-products {
		margin: 0 -2px;
	}
	.scroll-button {
		margin-bottom: 5px;
	}
	.product-grid.no-scroll {
		.product-block {
			margin: 4px 2px;
			width: calc(100% / 4 - 7px);
			.image {
				border: 0;
			}
		}
	}
}

.cheap_invoice {
	margin-bottom: 15px;
}

.storage_widget {
	border: 0 !important;
	box-shadow: 0px 2px 6px rgba(88, 66, 55, 0.1), 0px 0px 2px rgba(134, 96, 77, 0.2);
	border-radius: 8px;
	padding: 25px 25px 25px 135px;
	margin-bottom: 30px;
	background: url(#{$image-theme-path}ic_shop.png) 25px center no-repeat;
	& > span {
		color: $bk !important;
		b {
			font-weight: normal !important;
		}
	}
	& > span:nth-child(2) {

	}
	table tr {
		td {
			display: inline-block;
			padding: 0 !important;
			font-weight: 600;
			font-size: 20px;
			line-height: 24px;
		}
		td:first-child {
			display: none;
		}
		td:last-child span {
			margin: 0 0 0 15px;
			display: inline-block;
			font-weight: 600 !important;
			font-size: 16px !important;
			line-height: 24px !important;
			text-transform: uppercase;
			background: none !important;
			border-radius: 25px !important;
			color: $tc !important;
			border: 2px solid $tc;
			font-family: $f !important;
			&:hover {
				background: $tc !important;
				color: $wh !important;
			}
		}
	}
}